import { Field } from "../types/field";
export const stationFields: Field[] = [
  {
    name: "stationName",
    label: "Station Name",
    type: "text",
  },
  {
    name: "stationCode",
    label: "Station Code",
    type: "text",
    autoFilled: true,
    allowNull: true,
  },
  {
    name: "foreignFlg",
    label: "Foreign",
    type: "checkbox",
    defaultValue: false,
    linkedFields: [
      {
        name: "stateName",
        value: null,
      },
      {
        name: "country",
        value: null,
      },
    ],
  },
  {
    name: "stateName",
    label: "State Name",
    type: "lookup",
    lookupType: "state",
    defaultValue: null,
    disability: {
      type: "IN",
      field: "foreignFlg",
      values: [true],
    },
    lookupFilter: {
      type: "EQUALS",
      field: "stateCode",
      resolveValue: true,
      valueFieldName: "stateCode",
    },
    dependentFields: [
      {
        lookupFieldName: "id",
        fieldName: "stateCode",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "gstCode",
        fieldName: "gstCode",
      },
    ],
  },
  {
    name: "stateCode",
    label: "State Code",
    type: "text",
    defaultValue: "",
    autoFilled: true,
    allowNull: true,
  },
  {
    name: "gstCode",
    label: "GST Code",
    type: "text",
    defaultValue: "",
    autoFilled: true,
    allowNull: true,
  },
  {
    name: "country",
    label: "Country",
    type: "text",
    allowUpdate: true,
    defaultValue: "INDIA",
    disability: {
      type: "IN",
      field: "foreignFlg",
      values: [false],
    },
  },
];

export const stationColumns = [
  {
    name: "stationCode",
    label: "Station Code",
    type: "text",
  },
  {
    name: "stationName",
    label: "Station Name",
    type: "text",
  },

  {
    name: "foreignFlg",
    label: "Foreign",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "stateCode",
    label: "State Code",
    type: "text",
  },
  {
    name: "gstCode",
    label: "GST Code",
    type: "text",
  },
  {
    name: "country",
    label: "Country",
    type: "text",
  },
];

export const stationExcelColumns = [
  {
    name: "stationCode",
    label: "Station Code",
    type: "text",
  },
  {
    name: "stationName",
    label: "Station Name",
    type: "text",
  },

  {
    name: "foreignFlg",
    label: "Foreign",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "stateCode",
    label: "State Code",
    type: "text",
  },
  {
    name: "gstCode",
    label: "GST Code",
    type: "text",
  },
  {
    name: "country",
    label: "Country",
    type: "text",
  },
];

export const stationSearchColumns = [
  {
    name: "stationCode",
    label: "Station Code",
    type: "text",
  },
  {
    name: "stationName",
    label: "Station Name",
    type: "text",
  },

  {
    name: "foreignFlg",
    label: "Foreign",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.6,
    },
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "stateCode",
    label: "State Code",
    type: "text",
    props: {
      widthFactor: 0.6,
    },
  },
  {
    name: "gstCode",
    label: "GST Code",
    type: "text",
    props: {
      widthFactor: 0.6,
    },
  },
  {
    name: "country",
    label: "Country",
    type: "text",
  },
];
