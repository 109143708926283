import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { SnackbarContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";

import { SnackbarOrigin } from "@mui/material";

interface CustomSnackbarProps {
  open: boolean;
  onClose: (e, reason) => void;
  message: string;
  severity: "success" | "error" | "info" | "warning" | "";
  autoHideDuration?: number;
  anchorOrigin?: SnackbarOrigin;
}

const CustomSnackbar = ({
  open,
  onClose,
  message,
  severity,
  autoHideDuration,
  anchorOrigin = {
    vertical: "top",
    horizontal: "right",
  },
}: CustomSnackbarProps) => {
  const icon =
    severity === "success" ? (
      <CheckCircleOutlineIcon style={{ color: "green" }} />
    ) : severity === "error" ? (
      <ErrorOutlineIcon style={{ color: "red" }} />
    ) : severity === "warning" ? (
      <WarningOutlinedIcon style={{ color: "orange" }} />
    ) : severity === "info" ? (
      <InfoOutlinedIcon style={{ color: "#2196F3" }} />
    ) : null;

  return (
    <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={onClose} anchorOrigin={anchorOrigin}>
      <SnackbarContent
        message={
          <span style={{ display: "flex", alignItems: "center" }}>
            {icon}
            <span style={{ marginLeft: 8 }}>{message}</span>
          </span>
        }
        action={
          <IconButton size="small" aria-label="close" onClick={(e) => onClose(e, "cross")} style={{ color: "white" }}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Snackbar>
  );
};

export default CustomSnackbar;
