import axios from "axios";

export function setTokenHeader(token) {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
}

export function apiCall(method, path, data?, requestHeaders?, logout?): any {
  return new Promise((resolve, reject) => {
    return axios[method.toLowerCase()](path, data, requestHeaders)
      .then((res: any) => {
        return resolve(res.data);
      })
      .catch((err) => {
        console.error("[ApiCall] encountered error", err);
        if (err.response.status === 401) {
          if (logout) {
            logout();
          }
          window.location.href = "/";
        }
        return reject(err.response.data.error || err.response.data);
      });
  });
}
