import React, { useState, useMemo, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { debounce } from "@mui/material/utils";
import { apiCall } from "../../services/api";
import { getRecordsSearchRequestFilters, getRecordLookupParams, isEmptyObj } from "../../services/utils";
import { evaluateFilter } from "../../services/filterEvaluator";
import { LookupField as LookupFieldType } from "../../types/field";
import { useSnackBar } from "../providers/SnackBarProvider";

export default function LookupField(props) {
  let {
    keyValue = 0,
    handleRecordSelection,
    value = null,
    width,
    field,
    record,
    size = "medium",
    disabled = false,
    parentGlobalConstants = {},
    error,
    cancelled = false,
    showLabel = true,
    sx = {},
  } = props;

  let fieldCopy: LookupFieldType = { ...field };
  let {
    name,
    label,
    lookupType,
    lookupCategory,
    lookupFieldName,
    lookupFilter,
    dependentFields = [],
    lookupParams,
    emptyResponseMessage,
    warningMessageConfig,
  } = fieldCopy;

  let globalConstants = { ...parentGlobalConstants };
  const [inputValue, setInputValue] = useState("");
  const { dispatch: snackBarDispatch } = useSnackBar();

  useEffect(() => {
    if (isEmptyObj(value)) {
      setInputValue("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  function handleChange(event, newValue) {
    let fieldValue = newValue ? newValue.name : "";
    if (!isNaN(fieldValue)) {
      fieldValue = fieldValue.toString();
    }
    handleInputChange(fieldValue);
    handleRecordSelection({ target: { name, value: fieldValue } });
    changeFieldAndDependentValues(newValue, fieldValue);
  }

  function changeFieldAndDependentValues(newValue, fieldValue) {
    let newRecord = { ...record };
    dependentFields.forEach((dependentField) => {
      let value = null;
      if (newValue && dependentField.lookupFieldName) {
        if (
          dependentField.additionalFieldName &&
          newValue.additional &&
          newValue.additional[dependentField.additionalFieldName] &&
          !(newValue.additional[dependentField.additionalFieldName][dependentField.lookupFieldName] == null)
        ) {
          value = newValue.additional[dependentField.additionalFieldName][dependentField.lookupFieldName];
        } else if (newValue[dependentField.lookupFieldName]) {
          value = newValue[dependentField.lookupFieldName];
        }
      }
      newRecord[dependentField.fieldName] = value;
      handleRecordSelection({ target: { name: dependentField.fieldName, value } });
    });

    if (warningMessageConfig && warningMessageConfig.filter && evaluateFilter(warningMessageConfig.filter, newRecord)) {
      snackBarDispatch({ type: "set", message: warningMessageConfig.message, severity: "warning" });
    }
    return newRecord;
  }

  const debouncedHandleInputChange = debounce((newInputValue) => handleInputChange(newInputValue), 400);

  function handleInputChange(newInputValue) {
    setInputValue(newInputValue);
  }

  const fetchLookupValues = async (request) => {
    const response: any = await apiCall("post", `/api/entity/${lookupType}/lookup`, request);
    return response.results;
  };

  const request = {
    query: inputValue,
    filter: getRecordsSearchRequestFilters(record, lookupFilter, globalConstants),
    lookupFieldName,
    lookupCategory,
    lookupParams: getRecordLookupParams(record, lookupParams, globalConstants),
  };

  const { data, isLoading } = useQuery({
    queryKey: ["lookupValues", lookupType, JSON.stringify(request)],
    queryFn: () => fetchLookupValues(request),
    enabled: (inputValue === "" || inputValue !== value) && !disabled,
    refetchOnWindowFocus: false,
    gcTime: 60000,
    staleTime: 60000,
  });

  const options = useMemo(() => {
    if (Array.isArray(data)) {
      return data;
    } else {
      return [];
    }
  }, [data]);

  const getOptionsText = () => {
    if (isLoading) {
      return "Loading...";
    } else {
      return emptyResponseMessage ? emptyResponseMessage : "No match found";
    }
  };

  return (
    <>
      <Autocomplete
        id="record-search"
        key={keyValue}
        sx={{ width: { width }, fontSize: "1rem" }}
        getOptionLabel={(option) => (typeof option === "string" ? option : option.name)}
        filterOptions={(x) => x}
        options={options}
        value={value}
        isOptionEqualToValue={(option, value) => option.name === value || option.name.toString() === value}
        autoComplete
        includeInputInList
        size={size}
        disabled={disabled}
        noOptionsText={getOptionsText()}
        onChange={(event, newValue) => {
          handleChange(event, newValue);
        }}
        onInputChange={(event, newInputValue) => {
          debouncedHandleInputChange(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={showLabel ? label : ""}
            fullWidth
            error={!!error}
            size="small"
            onBlur={() => {
              setInputValue(""); // Reset the input value when focus is lost
            }}
            sx={{
              ...sx,
              textDecoration: cancelled ? "line-through" : "none",
              color: cancelled ? "red" : "inherit",
              "& .MuiInputBase-input": {
                fontSize: "smaller",
              },
              "& .MuiInputLabel-root": {
                fontSize: "smaller",
              },
            }}
          />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}>
                  {option.name}
                  <Typography variant="body2" color="text.secondary">
                    {option.id}
                    {option.description ? "," : ""} {option.description}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    </>
  );
}
