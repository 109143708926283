import React, { useState, useEffect } from "react";
import RecordPage from "./RecordPage";
import RecordTabularView from "./RecordTabularView";
import { useParams, useLocation, useHistory } from "react-router-dom";
import _ from "lodash";
import { getSearchColumnsForRecordType } from "../../services/standardEntityFieldService";
import { getDefaultValuesForFields } from "../../services/fieldsUtils";
import dayjs from "../../config/dayjsConfig";
import { canEditEntity, canViewEntity } from "../../services/utils";
import withAuth from "../../hocs/withAuth";
import { getOpeningStockDate } from "../../services/dateUtils";
function RecordManager({ searchFields, entityType, pageTitle, currentUser }) {
  let { recordType } = useParams<{ recordType: string }>();
  recordType = entityType ? entityType : recordType;
  if (_.isEmpty(searchFields)) {
    searchFields = getSearchColumnsForRecordType(recordType);
  }
  const today = new Date(); // Current date and time
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(today.getDate() - 30);
  let defaultValues = getDefaultValuesForFields(searchFields);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [recordSearchRequest, setRecordSeachRequest] = useState(defaultValues);
  const [startDate, setStartDate] = useState<dayjs.Dayjs>(getOpeningStockDate());
  const [endDate, setEndDate] = useState<dayjs.Dayjs>(dayjs(today));
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    setSelectedRecord(null);
  }, [location]);

  useEffect(() => {
    if (!canViewEntity(recordType, currentUser)) {
      history.push("/");
    }
  }, [recordType, currentUser, history]);

  function handleEndDateChange(date) {
    let newValue: dayjs.Dayjs;
    setEndDate((oldValue) => {
      try {
        let finalValue = date.toISOString();
        newValue = dayjs(finalValue);
      } catch (e) {
        newValue = dayjs(today);
      }
      return newValue;
    });
  }

  function handleStartDateChange(date) {
    let newValue: dayjs.Dayjs;
    setStartDate((oldValue) => {
      try {
        let finalValue = date.toISOString();
        newValue = dayjs(finalValue);
      } catch (e) {
        newValue = dayjs(today);
      }
      return newValue;
    });
  }

  useEffect(() => {
    setSelectedRecord(null);
    setRecordSeachRequest(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordType, entityType]);

  return (
    <>
      {selectedRecord && (
        <RecordPage
          recordId={selectedRecord.id}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
          isEditable={() => canEditEntity(recordType, currentUser)}
          creationAllowed={canEditEntity(recordType, currentUser)}
        />
      )}
      {!selectedRecord && (
        <RecordTabularView
          key={recordType}
          setSelectedRecord={setSelectedRecord}
          recordSearchRequest={recordSearchRequest}
          setRecordSeachRequest={setRecordSeachRequest}
          entityType={entityType}
          pageTitle={pageTitle}
          startDate={startDate}
          handleStartDateChange={handleStartDateChange}
          endDate={endDate}
          handleEndDateChange={handleEndDateChange}
          creationAllowed={canEditEntity(recordType, currentUser)}
        />
      )}
    </>
  );
}

export default withAuth(RecordManager);
