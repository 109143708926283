import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

function withAuth(ComponentToBeRendered) {
  function Authenticate({ currentUser, ...props }) {
    const history = useHistory();

    useEffect(() => {
      if (!currentUser.isAuthenticated) {
        history.push("/");
      }
    }, [currentUser.isAuthenticated, history]);

    return <ComponentToBeRendered currentUser={currentUser.user} {...props} />;
  }

  function mapStateToProps(state) {
    return { currentUser: state.currentUser };
  }

  return connect(mapStateToProps)(Authenticate);
}

export default withAuth;
