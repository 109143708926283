import { Switch } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import LookupField from "./LookupField";
import TableInput from "./TableInput";
import dayjs from "../../config/dayjsConfig";
import _ from "lodash";
import LookupTable from "./LookupTable";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { toUpperCase, isEmptyObj } from "../../services/utils";
import { evaluateFilter } from "../../services/filterEvaluator";
import Stack from "@mui/material/Stack";
import { evaluateValueScript, canChangeValue } from "../../services/utils";
import { isConstraintsSatisfied } from "../../services/utils";
import { getNewValueForField, translatePicklistValue } from "../../services/fieldsUtils";
import { getMinMaxDateForField } from "../../services/dateUtils";
import AttachmentComponent from "./AttachmentComponent";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { Field, LinkedField, FilteredValue } from "../../types/field";
import { resolveValueInternal } from "../../services/utils";

export const FormInputField = ({ field, width = "25vw", ...formProps }) => {
  const {
    key = 0,
    record,
    leafFields,
    setRecord,
    isUpdateForm,
    formDisabled,
    formApproved,
    formCancelled,
    handleChangeInternal = () => {},
    formErrors,
    setFormErrors,
    entityType,
    setError,
    globalConstants,
  } = formProps;

  const isEditDisabled = () => {
    return (formDisabled && field.name !== "cancelled" && field.name !== "submit") || field.autoFilled || disabled || field.displayOnly;
  };

  const isEditDisabledForUpdate = () => {
    return !field.allowUpdate || formApproved || (field.name !== "cancelled" && formCancelled);
  };

  const getFieldValue = ({ field, disabled }) => {
    if (!field) {
      return "";
    }

    if (disabled && typeof field.defaultValue !== "undefined" && record[field.name] !== field.defaultValue && field.setDefaultOnDisable) {
      setRecord((prevRecord) => {
        prevRecord[field.name] = field.defaultValue;
        return { ...prevRecord };
      });
      return field.defaultValue;
    }
    if (field.valueScript) {
      try {
        let initialRecord = { ...record };
        let result = evaluateValueScript(field.valueScript, initialRecord);
        if (result !== "" && record[field.name] !== result) {
          handleChange(
            {
              target: { name: field.name, value: result, type: "number" },
            },
            field
          );
        }
      } catch (error) {
        console.error("Error computing field value:", error);
        return "";
      }
    }
    if (field.type === "date") {
      return record && record[field.name] ? dayjs(record[field.name]) : null;
    }
    return record ? record[field.name] : field.type === "number" ? 0 : "";
  };

  const handleChange = (e, field, isLinkedField = false) => {
    // eslint-disable-next-line eqeqeq
    if (!canChangeValue(e, field)) {
      return;
    }
    handleRecordChange(e, field, isLinkedField);
    handleChangeInternal(e, field, handleRecordChange);
  };

  const handleRecordChange = (e, field: Field, isLinkedField = false) => {
    const { name, value, type, checked } = e.target;
    let newValue = getNewValueForField(type, checked, value);
    let currentField = fieldNameToFieldMap[name];
    setRecord((prevState) => {
      prevState[name] = newValue;
      return { ...prevState };
    });
    if (currentField && currentField.constraints) {
      if (!isConstraintsSatisfied(currentField.constraints, newValue, currentField, setError, setFormErrors, record)) {
        return;
      }
    }
    setFormErrors((prevFormErrors) => {
      prevFormErrors[name] = "";
      return { ...prevFormErrors };
    });
    setError("");

    let currentContext = { ...record, [name]: newValue };
    // we use isLinkedField to avoid infinite loop by avoiding transitive linked fields
    if (!isLinkedField && currentField && currentField.linkedFields) {
      currentField.linkedFields.forEach((childField: LinkedField) => {
        let { name, value, filteredValues, resolveValue, valueFieldName, isGlobalConstant } = childField;
        let selectedValue = value;
        if (resolveValue) {
          let resolvedValues = resolveValueInternal(isGlobalConstant, valueFieldName, currentContext, []);
          selectedValue = resolvedValues ? resolvedValues[0] : "";
        }
        if (filteredValues) {
          let selectedFilteredValue: FilteredValue = filteredValues.find((filteredValue: FilteredValue) => {
            return evaluateFilter(filteredValue.filter, currentContext);
          });
          if (selectedFilteredValue) {
            let { isGlobalConstant, valueFieldName, value, resolveValue } = selectedFilteredValue;
            selectedValue = value;
            if (resolveValue) {
              let resolvedValues = resolveValueInternal(isGlobalConstant, valueFieldName, currentContext, []);
              selectedValue = resolvedValues ? resolvedValues[0] : "";
            }
          }
        }
        if (selectedValue !== undefined) {
          handleChange({ target: { name, value: selectedValue } }, childField, true);
        }
      });
    }
  };

  const fieldNameToFieldMap = {};
  leafFields.forEach((field) => {
    fieldNameToFieldMap[field.name] = field;
  });

  width = field.props && field.props.width ? field.props.width : width;
  let disabled = false;
  if (field.hiddenForCreation && !isUpdateForm) {
    return <></>;
  }

  if (!_.isEmpty(field.visibility)) {
    let isVisible = evaluateFilter(field.visibility, record, globalConstants);
    if (!isVisible) {
      return null;
    }
  }
  if (!_.isEmpty(field.disability)) {
    disabled = disabled || evaluateFilter(field.disability, record);
  }
  disabled = disabled || (!isUpdateForm && field.disabledForCreation);

  let editDisabled = false;
  if (isEditDisabled() || (isUpdateForm && isEditDisabledForUpdate())) {
    editDisabled = true;
  }

  let fieldValue = getFieldValue({
    field,
    disabled,
  });

  switch (field.type) {
    case "text":
    case "number":
      return (
        <TextField
          id={field.name}
          key={key}
          name={field.name}
          label={field.label}
          onChange={(e) => handleChange(e, field)}
          disabled={editDisabled}
          error={formErrors[field.name]}
          // helperText={formErrors[field.name] ? formErrors[field.name] : null}
          type={"text"}
          sx={{
            mt: 2,
            width: width,
            backgroundColor: editDisabled ? null : "#F0F8FF",
            "& .MuiInputBase-input": {
              fontSize: "smaller",
            },
            "& .MuiInputLabel-root": {
              fontSize: "smaller",
            },
          }}
          onInput={(e) => toUpperCase(e, field)}
          required={!field.allowNull}
          value={!isEmptyObj(fieldValue) ? fieldValue : ""}
          InputLabelProps={{
            shrink: true, // Enable label animation
          }}
          size="small"
          inputProps={{
            min: "0", // Set the minimum value to 0
            step: "any", // Allow decimal input
          }}
          // size="small"
        />
      );

    case "date":
      let { minDate, maxDate } = getMinMaxDateForField(field, record, globalConstants);
      return (
        <DatePicker
          key={key}
          name={field.name}
          label={field.label}
          value={fieldValue}
          disabled={editDisabled}
          minDate={minDate}
          maxDate={maxDate}
          slotProps={{
            textField: {
              // helperText: formErrors[field.name]
              //   ? formErrors[field.name]
              //   : null,
              size: "small",
              error: formErrors[field.name] && formErrors[field.name] !== "",
            },
          }}
          sx={{
            mt: 2,
            width: width,
            "& .MuiInputBase-input": {
              fontSize: "smaller",
            },
            "& .MuiInputLabel-root": {
              fontSize: "smaller",
            },
            backgroundColor: editDisabled ? null : "#F0F8FF",
          }}
          format="DD/MM/YYYY"
          onChange={(date) => {
            handleChange(
              {
                target: { name: field.name, value: date, type: "date" },
              },
              field
            );
          }}
        />
      );

    case "textArea":
      return (
        <TextField
          key={key}
          id={field.name}
          name={field.name}
          label={field.label}
          onChange={(e) => handleChange(e, field)}
          disabled={editDisabled}
          type="text"
          sx={{
            mt: 2,
            width: width,
            "& .MuiInputBase-input": {
              fontSize: "smaller",
            },
            "& .MuiInputLabel-root": {
              fontSize: "smaller",
            },
            backgroundColor: editDisabled ? null : "#F0F8FF",
          }}
          error={formErrors[field.name]}
          // helperText={formErrors[field.name] ? formErrors[field.name] : null}
          multiline
          rows={4}
          onInput={(e) => toUpperCase(e, field)}
          required={!field.allowNull}
          value={record ? record[field.name] : ""}
          InputLabelProps={{
            shrink: true, // Enable label animation
          }}
          size="small"
        />
      );
    case "picklist":
      return editDisabled ? (
        <TextField
          key={key}
          id={field.name}
          name={field.name}
          label={field.label}
          disabled={true}
          type={"text"}
          sx={{
            width: width,
            "& .MuiInputBase-input": {
              fontSize: "smaller",
            },
            "& .MuiInputLabel-root": {
              fontSize: "smaller",
            },
            backgroundColor: editDisabled ? null : "#F0F8FF",
          }}
          size="small"
          value={!isEmptyObj(fieldValue) ? translatePicklistValue(fieldValue, field.values) : ""}
          InputLabelProps={{
            shrink: true, // Enable label animation
          }}
        />
      ) : (
        <FormControl sx={{ width: width }}>
          <InputLabel
            id={field.name}
            sx={{
              fontSize: "smaller",
              lineHeight: "1.5", // Adjust to match text alignment
              marginTop: !(record && record[field.name]) ? "-7px" : null,
            }}
          >
            {field.label}
          </InputLabel>
          <Select
            key={key}
            labelId={field.name}
            id={field.name}
            label={field.label}
            name={field.name}
            value={record ? record[field.name] : ""}
            error={formErrors[field.name]}
            sx={{
              padding: "2px 2px",
              "& .MuiInputBase-input": {
                fontSize: "smaller",
                padding: "5px",
                ml: 1,
              },
              "& .MuiInputLabel-root": {
                fontSize: "smaller",
              },
              backgroundColor: editDisabled ? null : "#F0F8FF",
            }}
            // helperText={
            //   formErrors[field.name] ? formErrors[field.name] : null
            // }
            size="small"
            disabled={editDisabled}
            required={!field.allowNull}
            onChange={(e) => handleChange(e, field)}
          >
            <MenuItem value="" disabled>
              {field.label}
            </MenuItem>
            {field.values
              .filter((valueObject) => (valueObject.visibility ? evaluateFilter(valueObject.visibility, record) : true))
              .map((valueObject) => (
                <MenuItem key={valueObject.value} value={valueObject.value}>
                  {valueObject.label}
                </MenuItem>
              ))}
            {record && record[field.name] != null && record[field.name] !== "" && field.allowNull && (
              <MenuItem value="" sx={{ backgroundColor: "lightgray" }}>
                Clear Selection
              </MenuItem>
            )}
          </Select>
        </FormControl>
      );
    case "multipicklist":
      return (
        <FormControl sx={{ width: width }}>
          <InputLabel id={field.name}>{field.label}</InputLabel>
          <Select
            key={key}
            labelId={field.name}
            id={field.name}
            label={field.label}
            name={field.name}
            multiple
            value={record ? record[field.name] || [] : []}
            error={formErrors[field.name]}
            disabled={editDisabled}
            required={!field.allowNull}
            onChange={(e) => handleChange(e, field)}
            size="small"
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => {
                  let label = field.values.find((item) => item.value === value).label;
                  return <Chip key={value} label={label} />;
                })}
              </Box>
            )}
          >
            <MenuItem value="" disabled>
              {field.label}
            </MenuItem>
            {field.values.map((valueObject) => (
              <MenuItem key={valueObject.value} value={valueObject.value}>
                <Checkbox checked={record && record[field.name] ? record[field.name].indexOf(valueObject.value) > -1 : false} />
                <ListItemText primary={valueObject.label} />
              </MenuItem>
            ))}
            {record && record[field.name] != null && record[field.name] !== "" && field.allowNull && (
              <MenuItem value="" sx={{ backgroundColor: "lightgray" }}>
                Clear Selection
              </MenuItem>
            )}
          </Select>
        </FormControl>
      );

    case "checkbox":
      return (
        <FormGroup>
          <FormControlLabel
            required
            control={
              <Switch
                checked={record ? record[field.name] : false}
                name={field.name}
                key={key}
                onChange={(e) => handleChange(e, field)}
                disabled={editDisabled}
                value={fieldValue}
                inputProps={{ "aria-label": "controlled" }}
                size="small"
              />
            }
            label={field.label}
          />
        </FormGroup>
      );

    case "lookup":
      return (
        <LookupField
          sx={{ backgroundColor: editDisabled ? null : "#F0F8FF" }}
          width={width}
          field={field}
          record={record}
          key={key}
          disabled={editDisabled}
          value={record[field.name]}
          error={formErrors[field.name]}
          // helperText={formErrors[field.name] ? formErrors[field.name] : null}
          handleRecordSelection={(e) => handleChange(e, field)}
          parentGlobalConstants={globalConstants}
        />
      );
    case "lookupTable":
      return <LookupTable key={key} recordType="material-inward" pageTitle="Material Inward" />;
    case "formTable":
      return (isUpdateForm || field.autoFilled) && !(record && record[field.name]) ? (
        <TableInput
          key={`${key}_1`}
          columns={field.tableFields}
          title={field.label}
          fieldName={field.name}
          handleRecordSelection={(e) => handleChange(e, field)}
          values={[{}]}
          parentGlobalConstants={globalConstants}
          tableDisabled={editDisabled}
          entityType={entityType}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          setError={setError}
          mode={isUpdateForm ? "update" : "add"}
          tableWidth={width}
        />
      ) : (
        <TableInput
          key={`${key}_2`}
          columns={field.tableFields}
          title={field.label}
          fieldName={field.name}
          handleRecordSelection={(e) => handleChange(e, field)}
          values={record[field.name]}
          parentGlobalConstants={globalConstants}
          tableDisabled={editDisabled}
          entityType={entityType}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          setError={setError}
          mode={isUpdateForm ? "update" : "add"}
          tableWidth={width}
        />
      );
    case "attachment":
      return (isUpdateForm || field.autoFilled) && !(record && record[field.name]) ? (
        <AttachmentComponent
          key={`${key}_1`}
          fieldName={field.name}
          handleRecordSelection={(e) => handleChange(e, field)}
          values={[{}]}
          parentGlobalConstants={globalConstants}
          disabled={editDisabled}
          entityType={entityType}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          setError={setError}
          mode={isUpdateForm ? "update" : "add"}
          width={width}
        />
      ) : (
        <AttachmentComponent
          key={`${key}_2`}
          fieldName={field.name}
          handleRecordSelection={(e) => handleChange(e, field)}
          values={record[field.name]}
          parentGlobalConstants={globalConstants}
          disabled={editDisabled}
          entityType={entityType}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          setError={setError}
          mode={isUpdateForm ? "update" : "add"}
          width={width}
        />
      );
    case "formSection":
      return (
        <Stack
          key={key}
          direction={field.alignment ? field.alignment : "row"}
          alignItems={"top"}
          justifyContent={field.props && field.props.justify ? field.props.justify : "center"}
          spacing={field.props && field.props.spacing ? field.props.spacing : "1vw"}
        >
          {field.childFields.map((childField, index) => (
            <FormInputField
              key={`${key}.${index}`}
              field={childField}
              width={field.props && field.props.width ? field.props.width : "12vw"}
              {...formProps}
            />
          ))}
        </Stack>
      );

    default:
      return <></>;
  }
};
