import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

const allowedTypes = ["number", "object", "boolean", "date", "string"];

export default function RecordTable({
  records = [],
  handleRecordSelection,
  recordsTotalCount,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  rowsPerPage,
  columns,
  tableContainerHeight,
}) {
  function getColumnCellValue(column, value) {
    if (column && value && column.type === "checkbox") {
      return value.toString();
    }
    if (column.format && allowedTypes.includes(typeof value)) {
      return column.format(value);
    }
    return value;
  }

  return (
    <TableContainer sx={{ maxHeight: tableContainerHeight }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow key={"title"}>
            {columns.map((column) => (
              <TableCell
                key={column.name}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  fontSize: "0.75rem",
                  fontWeight: "bold",
                  padding: "1.4vh",
                  backgroundColor: "#F0F8FF",
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {records.map((row, rowNo) => {
            return (
              <TableRow key={rowNo} onClick={() => handleRecordSelection(row.id)} hover role="checkbox" tabIndex={-1}>
                {columns.map((column, columNo) => {
                  const value = row[column.name];
                  return (
                    <TableCell
                      key={`${rowNo}_${columNo}`}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        fontSize: "0.70rem",
                        padding: "1.4vh",
                        backgroundColor: "#F7F6FF",
                      }}
                    >
                      {getColumnCellValue(column, value)}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30, 50]}
        component="div"
        count={recordsTotalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={{
          backgroundColor: "#F0F8FF",
        }}
      />
    </TableContainer>
  );
}
