import { useState } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { isEmptyObj } from "../../services/utils";
import { useParams } from "react-router-dom";
import { apiCall } from "../../services/api";
import { setAuthorizationToken } from "../../store/actions/auth";

const ForgotPassword = () => {
  const history = useHistory();
  const [userPin, setUserPin] = useState("");
  const [formError, setFormError] = useState("");
  const [checkPin, setCheckPin] = useState(false); // Track the checkbox state

  let { userId } = useParams<{ userId: string }>();

  const handleSubmit = async (e) => {
    e.preventDefault();
    apiCall("post", "/api/auth/verifyPin", { userId, userPin })
      .then((response) => {
        setCheckPin(false);
        let { token } = response;
        localStorage.setItem("jwtToken", token);
        setAuthorizationToken(token);
        history.push(`/change-password`);
      })
      .catch((err) => {
        console.error("error inside forgot password", err);
        setFormError(err.message || err);
      });
  };

  const handleChange = (e) => {
    setUserPin(e.target.value);
    setFormError("");
  };

  function resetPassword(e): void {
    if (!checkPin) {
      apiCall("post", "/api/auth/resetPassword", { userId })
        .then((response) => {
          setCheckPin(true);
        })
        .catch((err) => {
          console.error("error inside forgot password", err);
          setFormError(err.message || err);
        });
    } else {
      handleSubmit(e);
    }
  }

  return (
    <Box component="span" sx={{ p: 10, m: 5 }}>
      <div className="row justify-content-md-center text-center">
        <div className="col-md-6">
          <form onSubmit={handleSubmit}>
            <Stack direction="column" alignItems="center" spacing={4}>
              <Typography variant="h5" gutterBottom>
                Forgot Password!
              </Typography>
              <Paper sx={{ p: 5 }} elevation={10}>
                <Stack direction="column" alignItems="center" spacing={2}>
                  {userId && (
                    <TextField
                      id="userId"
                      name="userId"
                      label="User Id"
                      type="text"
                      required
                      sx={{ m: 2, width: "30ch" }}
                      value={userId}
                      disabled={true}
                    />
                  )}
                  {!checkPin && (
                    <Typography sx={{ fontSize: "small" }} variant="body2" gutterBottom>
                      Click continue to reset your password!!
                    </Typography>
                  )}
                  {checkPin && (
                    <>
                      <TextField
                        id="userPin"
                        name="userPin"
                        label="Verification Code"
                        onChange={handleChange}
                        error={!isEmptyObj(formError)}
                        helperText={formError ? formError : null}
                        type="text"
                        required
                        sx={{ m: 2, width: "30ch" }}
                        value={userPin}
                      />
                      <Typography sx={{ fontSize: "small" }} variant="body2" gutterBottom>
                        Enter the code sent to your registered email
                      </Typography>
                    </>
                  )}
                </Stack>
              </Paper>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Button variant="contained" color="error" component={Link} to={`/login`}>
                  Cancel
                </Button>
                {!checkPin && (
                  <Button disabled={isEmptyObj(userId)} variant="contained" onClick={resetPassword}>
                    Continue
                  </Button>
                )}
                {checkPin && (
                  <Button disabled={isEmptyObj(userId)} variant="contained" onClick={handleSubmit}>
                    Verify
                  </Button>
                )}
              </Stack>
              {formError && <div className="alert alert-danger">{formError}</div>}
            </Stack>
          </form>
        </div>
      </div>
    </Box>
  );
};

export default ForgotPassword;
