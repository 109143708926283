import { apiCall } from "../services/api";
import { useQuery } from "@tanstack/react-query";
import { useSetAppEnv } from "../config/AppEnvProvider";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import React from "react";
import { initAppEnv } from "../config/configHolder";
import { logout } from "../store/actions/auth";

// Fetch function for AppEnv
const fetchAppEnv = async (logout) => {
  return await apiCall("get", `/api/init/initMetadata/`, null, null, logout);
};

// Custom hook to fetch AppEnv using react-query
const useAppEnvQuery = (logout, currentUser) => {
  return useQuery({
    queryKey: ["envAppQuery"],
    queryFn: () => fetchAppEnv(logout),
    refetchOnWindowFocus: false,
    enabled: currentUser.isAuthenticated,
    gcTime: 2000,
    staleTime: 2000,
  });
};

const WithInitMetadata = ({ currentUser, children, logout }) => {
  const history = useHistory();
  const setAppEnv = useSetAppEnv();
  const [loading, setLoading] = React.useState(true);
  const { data: response, isLoading, error, status } = useAppEnvQuery(logout, currentUser);
  const appEnv = React.useMemo(() => ({ ...response, currentUser }), [response, currentUser]);

  useEffect(() => {
    // Check if the user is authenticated
    if (!currentUser.isAuthenticated) {
      history.push("/login");
    }
  }, [currentUser.isAuthenticated, history]);

  useEffect(() => {
    // Set the AppEnv when it's fetched and ready
    if (appEnv && !isLoading) {
      console.log("archit setting appenv", appEnv);
      setAppEnv(appEnv);
      initAppEnv(appEnv);
      setLoading(false);
    }
  }, [appEnv, isLoading, setAppEnv]);

  if (error) {
    console.log("archit error", error, status);
    logout();
    history.push("/");
    return <div>Error loading App Environment</div>;
  }

  if (isLoading || loading) {
    console.log("archit is loading");
    return <div>Loading App Environment...</div>;
  }

  console.log("archit appenv", appEnv);
  // Clone and pass currentUser to all children components
  const renderChildrenWithProps = React.Children.map(children, (child) => React.cloneElement(child, { currentUser }));
  return <>{renderChildrenWithProps}</>;
};

function mapStateToProps(state) {
  return { currentUser: state.currentUser };
}

// Connect WithInitMetadata to Redux state to access `currentUser`
export default connect(mapStateToProps, { logout })(WithInitMetadata);
