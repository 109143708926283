import { Switch, Route, withRouter, Redirect, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import Homepage from "../components/Homepage";
import AuthForm from "../components/auth/AuthForm";
import { authUser, logout } from "../store/actions/auth";
import { removeError } from "../store/actions/errors";
import ConfirmLogin from "../components/auth/ConfirmLogin";
import VerifyTFAForm from "../components/auth/VerifyTFAForm";
import OpenView from "../components/open/OpenView";
import ForgotPassword from "../components/auth/ForgotPassword";
import ChangePassword from "../components/auth/ChangePassword";
import ProtectedRoutes from "./ProtectedRoutes";
interface MainProps extends RouteComponentProps {
  authUser: (username: string, password: string) => void;
  errors: string[];
  removeError: () => void;
  currentUser: {
    isAuthenticated: boolean;
  };
}

const Main: React.FC<MainProps> = (props) => {
  const { authUser, errors, removeError, currentUser } = props;
  return (
    <div className="container">
      <Switch>
        <Route exact path="/" render={() => <Homepage currentUser={currentUser} />} />
        <Route
          exact
          path="/login"
          render={(props) => {
            return currentUser.isAuthenticated ? (
              <Redirect to="/" />
            ) : (
              <AuthForm removeError={removeError} errors={errors} onAuth={authUser} buttonText="Login" heading="Sign In" {...props} />
            );
          }}
        />
        <Route
          exact
          path="/signup"
          render={(props) => {
            return currentUser.isAuthenticated ? (
              <Redirect to="/" />
            ) : (
              <AuthForm removeError={removeError} errors={errors} onAuth={authUser} signUp buttonText="Sign Up" heading="Sign Up" {...props} />
            );
          }}
        />
        <Route path="/open/entity" render={() => <OpenView />} />
        <Route path="/confirmLogin" render={() => <ConfirmLogin onAuth={authUser} />} />
        <Route path="/verifyTFA/:userId" render={() => <VerifyTFAForm onAuth={authUser} />} />
        <Route path="/forgotPassword/:userId" render={() => <ForgotPassword />} />
        <Route path="/change-password/" render={() => <ChangePassword />} />
        <ProtectedRoutes />
        <Redirect to="/" />
      </Switch>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
    errors: state.errors,
  };
}

const ConnectedMain = connect(mapStateToProps, { authUser, removeError, logout })(Main);
export default withRouter(ConnectedMain);
