import withAuth from "../../hocs/withAuth";
import GenerateReportForm from "./GenerateReportForm";
import { translatePicklistValue } from "../../services/fieldsUtils";
import {
  getStockInOutFields,
  stockInOutCategoryValues,
  stockInOutLocatorSummary,
  stockInOutLocatorDetail,
  stockInOutProjectSummary,
  stockInOutProjectDetail,
} from "./formFields/stockInOutForms";
import { getOpeningStockDate, getToday } from "../../services/dateUtils";

const getExcelColumns = (searchRequest) => {
  return reportCategoryToExcelColumns[searchRequest.reportCategory];
};

const reportCategoryToExcelColumns = {
  LOCATOR_SUMMARY: stockInOutLocatorSummary,
  LOCATOR_DETAIL: stockInOutLocatorDetail,
  PROJECT_SUMMARY: stockInOutProjectSummary,
  PROJECT_DETAIL: stockInOutProjectDetail,
};

const StockReportForm = ({ formMinWidth = "40vw" }) => {
  return (
    <GenerateReportForm
      formMinWidth={formMinWidth}
      fields={getStockInOutFields()}
      getExcelColumns={getExcelColumns}
      pageTitle={"Stock In Out Report"}
      getReportName={(searchRequest) => `Stock ${translatePicklistValue(searchRequest.reportCategory, stockInOutCategoryValues)}`}
      reportType={"stock-in-out"}
      preProcessReportSearchRequest={(searchRequest) => {
        searchRequest.fromDocDate = searchRequest.fromDocDate ? searchRequest.fromDocDate : getOpeningStockDate();
        searchRequest.toDocDate = searchRequest.toDocDate ? searchRequest.toDocDate : getToday();
        searchRequest.stockDate = searchRequest.stockDate ? searchRequest.stockDate : getToday();
        return searchRequest;
      }}
    />
  );
};

export default withAuth(StockReportForm);
