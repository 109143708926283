import React, { useState } from "react";
import Button from "@mui/material/Button";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { generatePDF } from "../../services/pdfContentService";

const PDFGenerator = ({ generateContent, record, sameTab = false }) => {
  const [loading, setLoading] = useState(false);

  const generatePDFDoc = (e) => {
    e.preventDefault();
    setLoading(true);
    generatePDF(generateContent, sameTab);
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <Box sx={{ ml: 22, display: "flex" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Button sx={{ width: "45px", height: "40px" }} variant="contained" type="submit" onClick={generatePDFDoc} color="secondary">
            <PictureAsPdfIcon />
          </Button>
        </>
      )}
    </>
  );
};

export default PDFGenerator;
