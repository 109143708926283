import { Switch, Route, withRouter } from "react-router-dom";
import WithInitMetadata from "./WithInitMetadata";
import UserViewForm from "../components/users/UserViewForm";
import RecordAdditionForm from "../components/recordStandardForms/RecordAdditionForm";
import MaterialInwardForm from "../components/transactionEntities/materialInward/MaterialInwardForm";
import MaterialOutwardForm from "../components/transactionEntities/materialOutward/MaterialOutwardForm";
import RecordPage from "../components/recordStandardForms/RecordPage";
import MaterialInwardUpdationForm from "../components/transactionEntities/materialInward/MaterialInwardUpdationForm";
import RecordManager from "../components/recordStandardForms/RecordManager";
import StockReportForm from "../components/reports/StockListingForm";
import StockAgeingForm from "../components/reports/StockAgeingForm";
import StockOpeningClosingForm from "../components/reports/StockOpeningClosingForm";
import UserAdditionForm from "../components/users/UserAdditionForm";
import GatePassForm from "../components/transactionEntities/gatePass/GatePassForm";
import CBSheetForm from "../components/transactionEntities/cbSheet/CBSheetForm";
import StockInOutForm from "../components/reports/StockInOutForm";
import StockLedgerForm from "../components/reports/StockLedgerForm";
import MyProfileForm from "../components/users/MyProfileForm";

const ProtectedRoutes = () => {
  return (
    <div className="container">
      <WithInitMetadata>
        <Switch>
          <Route path="/user/view" render={() => <UserViewForm />} />
          <Route path="/my-profile/view" render={() => <MyProfileForm />} />
          <Route path="/my-profile/update/" render={() => <MyProfileForm />} />
          <Route path="/gate-pass/add" render={() => <GatePassForm />} />
          <Route path="/cb-sheet/add" render={() => <CBSheetForm />} />
          <Route path="/cb-sheet/update/:id" render={() => <CBSheetForm />} />
          <Route path="/material-inward/add" render={() => <MaterialInwardForm />} />
          <Route path="/material-inward/update/:id" render={() => <MaterialInwardUpdationForm />} />
          <Route path="/material-outward/add" render={() => <MaterialOutwardForm />} />
          <Route path="/material-outward/update/:id" render={() => <MaterialOutwardForm />} />
          <Route path="/user/update/:id" render={() => <UserAdditionForm />} />
          <Route path="/:recordType/view/:id" render={() => <RecordPage />} />
          <Route path="/:recordType/view" render={() => <RecordManager />} />
          <Route path="/:recordType/update/:id" render={() => <RecordAdditionForm />} />
          <Route path="/:recordType/add" render={() => <RecordAdditionForm />} />
          <Route path="/report/stock" render={() => <StockReportForm />} />
          <Route path="/report/stock-ageing" render={() => <StockAgeingForm />} />
          <Route path="/report/stock-opening-closing" render={() => <StockOpeningClosingForm />} />
          <Route path="/report/stock-in-out" render={() => <StockInOutForm />} />
          <Route path="/report/stock-ledger" render={() => <StockLedgerForm />} />
        </Switch>
      </WithInitMetadata>
    </div>
  );
};

export default withRouter(ProtectedRoutes);
