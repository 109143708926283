import React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

const ConfirmLogin = ({ onAuth }) => {
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await onAuth("confirmLogin", null);
      history.push("/");
    } catch {
      return;
    }
  };

  return (
    <Box component="span" sx={{ p: 10, m: 5 }}>
      <div className="row justify-content-md-center text-center">
        <div className="col-md-6">
          <form>
            <Stack direction="column" alignItems="center" spacing={4}>
              <h3>Confirm Login</h3>
              <Paper sx={{ m: 5 }} elevation={10}>
                <p>
                  A session is already active for this user ID on another system. Are you sure you want to terminate the existing session and continue
                  ?
                </p>
              </Paper>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Button variant="contained" color="error" component={Link} to={`/`}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={handleSubmit}>
                  Confirm Login
                </Button>
              </Stack>
            </Stack>
          </form>
        </div>
      </div>
    </Box>
  );
};

export default ConfirmLogin;
