import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { apiCall } from "../../services/api";
import store from "../../store";
import { addError } from "../../store/actions/errors";
import { validateUser } from "../../services/userUtils";
import { toUpperCase } from "../../services/utils";
import { setAuthorizationToken, setCurrentUser } from "../../store/actions/auth";
import jwtDecode from "jwt-decode";

const AuthForm = (props) => {
  const [user, setUser] = useState({
    userId: "",
    name: "",
    password: "",
    email: "",
    mobile: null,
    setupTFA: false,
    tfaAuthType: "EMAIL",
  });
  interface FormErrors {
    userId?: string;
    password?: string;
    name?: string;
    email?: string;
    mobile?: string;
  }

  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [forgotPassword, setForgotPassword] = useState(false);
  const [createdUser, setCreatedUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const { name, userId, password, email, mobile } = user;
  const { signUp, heading, buttonText, errors, onAuth, removeError } = props;

  useEffect(() => {
    if (localStorage.jwtToken) {
      setAuthorizationToken(localStorage.jwtToken);
      // prevent someone from manually tampering with the key of jwtToken in localStorage
      try {
        let currentUser = jwtDecode(localStorage.jwtToken);
        store.dispatch(setCurrentUser(currentUser));
      } catch (e) {
        localStorage.removeItem("jwtToken");
        store.dispatch(setCurrentUser({}));
      }
      history.push("/");
      return;
    }
  }, [history]);

  useEffect(() => {
    return () => {
      removeError();
    };
  }, [removeError]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (signUp) {
      if (!validateUser({ user, setFormErrors })) {
        console.error("Invalid Form Input Params");
        return;
      }
      saveNewUser(user);
    } else {
      if (localStorage.jwtToken) {
        setAuthorizationToken(localStorage.jwtToken);
        // prevent someone from manually tampering with the key of jwtToken in localStorage
        try {
          let currentUser = jwtDecode(localStorage.jwtToken);
          store.dispatch(setCurrentUser(currentUser));
        } catch (e) {
          localStorage.removeItem("jwtToken");
          store.dispatch(setCurrentUser({}));
        }
        history.push("/");
        return;
      }
      try {
        let result = await onAuth("signin", user);
        console.log("result", result);
        if (result.isCurrentSessionValid) {
          window.location.href = "/";
        } else if (result.validExistingSession) {
          history.push("/confirmLogin");
        } else if (result.verifyTFA) {
          history.push(`/verifyTFA/${result.userId}`);
        } else {
          history.push("/");
        }
      } catch (err) {
        console.log("exception", err);
        return;
      }
    }
  };

  function saveNewUser(user) {
    setLoading(true);
    apiCall("post", "/api/auth/signup/", user)
      .then((response) => {
        setCreatedUser(response);
        removeError();
      })
      .catch((err) => {
        console.error("error inside saving new user", err);
        store.dispatch(addError(err.message || err));
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleForgotPassword = async (userId) => {
    setForgotPassword(true);
    history.push(`/forgotPassword/${userId}`);
  };

  const handleChange = (e) => {
    removeError();
    setFormErrors((prevErrors) => ({ ...prevErrors, [e.target.name]: "" }));
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <Box component="span" sx={{ p: 10, m: 5 }}>
        <div className="row justify-content-md-center text-center">
          <div className="col-md-6">
            <form onSubmit={handleSubmit}>
              <Stack direction="column" alignItems="center" spacing={4}>
                <h4>{heading}</h4>
                <Paper sx={{ m: 5, backgroundColor: "#F7F6FF" }} elevation={10}>
                  <Stack sx={{ m: 5, mt: 5 }} direction="column" alignItems="center" spacing={2}>
                    <TextField
                      id="userId"
                      name="userId"
                      label="User Id"
                      error={!!formErrors.userId}
                      onInput={toUpperCase}
                      onChange={handleChange}
                      type="text"
                      helperText={formErrors.userId ? formErrors.userId : null}
                      required
                      sx={{ m: 2, width: "30ch" }}
                      value={userId}
                    />
                    <TextField
                      id="password"
                      name="password"
                      label="Password"
                      onChange={handleChange}
                      type="password"
                      error={!!formErrors.password}
                      helperText={formErrors.password ? formErrors.password : null}
                      required
                      sx={{ m: 2, width: "30ch" }}
                      value={password}
                      disabled={forgotPassword}
                    />
                    {signUp && (
                      <>
                        <TextField
                          id="name"
                          name="name"
                          label="Full Name"
                          onChange={handleChange}
                          onInput={toUpperCase}
                          type="text"
                          error={!!formErrors.name}
                          helperText={formErrors.name ? formErrors.name : null}
                          required
                          sx={{ m: 2, width: "30ch" }}
                          value={name}
                        />
                        <TextField
                          id="email"
                          name="email"
                          label="Email Id"
                          onChange={handleChange}
                          type="text"
                          error={!!formErrors.email}
                          helperText={formErrors.email ? formErrors.email : null}
                          required
                          sx={{ m: 2, width: "30ch" }}
                          value={email}
                        />
                        <TextField
                          id="mobile"
                          name="mobile"
                          label="Mobile"
                          onChange={handleChange}
                          type="number"
                          error={!!formErrors.mobile}
                          helperText={formErrors.mobile ? formErrors.mobile : null}
                          required
                          sx={{ m: 2, width: "30ch" }}
                          value={mobile}
                        />
                      </>
                    )}
                    {/* Add 'Forgot Password?' link for sign-in form */}
                    {!signUp && userId && (
                      <Button
                        onClick={() => handleForgotPassword(userId)}
                        style={{
                          marginTop: "5px",
                          marginLeft: "auto",
                          textDecoration: "underline",
                          color: "blue",
                          fontSize: "small",
                          textAlign: "left",
                          textTransform: "none",
                        }}
                      >
                        Forgot Password?
                      </Button>
                    )}
                  </Stack>
                </Paper>
                {!forgotPassword && (
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Button disabled={createdUser} variant="contained" color="error" component={Link} to={`/`}>
                      Cancel
                    </Button>
                    <Button disabled={createdUser || loading} variant="contained" type="submit">
                      {buttonText}
                    </Button>
                  </Stack>
                )}
                {forgotPassword && (
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        setForgotPassword(false);
                        removeError();
                      }}
                    >
                      Cancel
                    </Button>
                  </Stack>
                )}
                {errors.message && <Alert severity="error">{errors.message}</Alert>}
                {createdUser && !createdUser.approved && (
                  <Alert
                    onClose={() => {
                      history.push("/");
                    }}
                  >
                    User created successfully for {createdUser.name} with userId: {createdUser.userId}. <br />
                    You may proceed to login after your user is approved by the system admin.
                  </Alert>
                )}
                {createdUser && createdUser.approved && (
                  <Alert
                    onClose={() => {
                      history.push("/");
                    }}
                  >
                    User created successfully for {createdUser.name} with userId: {createdUser.userId}. <br />
                    You may proceed to login as your user is pre-approved.
                  </Alert>
                )}
              </Stack>
            </form>
          </div>
        </div>
      </Box>
    </div>
  );
};

AuthForm.propTypes = {
  buttonText: PropTypes.string,
  errors: PropTypes.object,
  heading: PropTypes.string,
  history: PropTypes.object,
  onAuth: PropTypes.func,
  signUp: PropTypes.bool,
  removeError: PropTypes.func,
};

export default AuthForm;
